<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col :span="20">
            <self-e-shop-group :income-code="searchCondition.income_code" @choiceIncome="choicePlatform" @incomeOptions="incomeOptions"></self-e-shop-group>
          </el-col>
          <el-col :span="4" style="text-align: right">
            <export-link ref="refExportLink" :can-export="userPermissions.indexOf('shop_data_export') !== -1"
                         @export="exportShopData" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form ref="searchForm" size="small" :inline="true" :model="searchCondition">
              <el-form-item label="">
                <el-radio-group v-model="checkedRadio" @change="radioChange">
                  <el-radio-button :label="option.value" v-for="(option,index) in dayRange" :key="index">{{
                      option.label
                    }}
                  </el-radio-button>
                  <!--              <el-radio-button :label="1">昨天</el-radio-button>-->
                  <!--              <el-radio-button :label="7">近7日</el-radio-button>-->
                  <!--              <el-radio-button :label="30">近30日</el-radio-button>-->
                </el-radio-group>
              </el-form-item>
              <el-form-item label="">
                <el-date-picker
                    v-model="ym"
                    type="month"
                    value-format="yyyy-MM"
                    placeholder="选择月份" style="width: 120px" @change="changeMonth">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <el-date-picker
                    v-model="searchCondition.time_value"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd" @change="changeTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <artist-search @handleSelect="chooseArtist" size="small" />
              </el-form-item>
            </el-form>
          </el-col>

        </el-row>
        <!--   table列表     -->
        <div class="default-table">
          <ApeTable ref="apeTable"
                    :data="dataList"
                    :columns="[]"
                    :loading="loadingStatus"
                    :pagingData="pagingData"
                    @switchPaging="switchPaging"
                    highlight-current-row
                    border>
            <!--        <el-table-column prop="rank" label="排名" min-width="80" align="center"/>-->
            <el-table-column prop="time_day" label="日期" min-width="100" align="center" slot="first-column" />
            <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center" show-overflow-tooltip slot="first-column">
              <template slot-scope="scope">
                <artist-nickname :info="scope.row" />
              </template>
            </el-table-column>
            <template v-for="(column,index) in columns">
              <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                               :min-width="`${column.width}`" align="right" header-align="center">
                <template slot-scope="{row}">
                  <span v-if="column.type==='number'">{{ numFormat(row[column.value]) }}</span>
                  <span v-else-if="column.type==='rate'" class="rate">{{ rateFormat(row[column.value]) }}</span>
                  <span v-else-if="column.type==='money'">{{ moneyFormat(row[column.value]) }}</span>
                  <span v-else>{{ row[column.value] }}</span>
                </template>
              </el-table-column>
            </template>
            <!--        <el-table-column prop="num_visitor" label="访客数" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span>{{ numFormat(scope.row.num_visitor) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="num_buyer" label="买家数" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span>{{ numFormat(scope.row.num_buyer) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="rate_payment" label="支付转化率(%)" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span class="rate">{{ rateFormat(scope.row.rate_payment) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="amount_payment" label="支付金额" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span>{{ moneyFormat(scope.row.amount_payment) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="c_unit_price" label="客单价" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span>{{ moneyFormat(scope.row.c_unit_price) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="amount_refund" label="申请退款金额" min-width="100" align="right" header-align="center">-->
            <!--          <template slot-scope="scope">-->
            <!--            <span>{{ moneyFormat(scope.row.amount_refund) }}</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="d_score" label="描述评分（D）" min-width="100" align="center"/>-->
            <!--        <el-table-column prop="s_score" label="服务评分（S）" min-width="100" align="center"/>-->
            <!--        <el-table-column prop="r_score" label="物流评分（R）" min-width="100" align="center"/>-->
          </ApeTable>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import ApeDrawer from "@/components/ApeDrawer";
import { mapGetters } from "vuex";
import SelfEShopGroup from "@/pages/platform/components/SelfEShopGroup";
import ArtistNickname from "@/pages/artist/ArtistNickname";
import ExportLink from "@/components/export/ExportLink";
import { numberFormat } from "@/utils/utils";
import ArtistSearch from "@/components/artist/ArtistSearch";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
export default {
  name: "ShopDataList",
  components: {
    ApeTable,
    ApeDrawer,
    SelfEShopGroup,
    ArtistNickname,
    ArtistSearch,
    ExportLink,
    PageHeaderLayout
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [
        // {
        //   rank: 1,
        //   nickname: 'JJG',
        //   num_visitor: 300,
        //   num_buyer: 100,
        //   rate_payment: '10%',
        //   amount_payment: '10,330',
        //   c_unit_price: 1200,
        //   amount_refund: 11100,
        //   d_score: 5,
        //   s_score: 5,
        //   r_score: 5
        // }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      groupOptions: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      checkedRadio: null,
      dayRange: [
        {label: '昨天', value: 1},
        {label: '近7天', value: 7},
        {label: '近30天', value: 30},
      ],
      ym: null,
      rangYm: null,
      incomes: [{code: 'shop_tb', name: '淘宝店'}, {code: 'shop_dy', name: '抖音店'}, {code: 'shop_ks', name: '快手店'}],//{code: 'shop_tb', name: '淘宝店'}, {code: 'shop_dy', name: '抖音店'}, {code: 'shop_ks', name: '快手店'}
      searchCondition: {
        income_code: '',
        platform_code: 'self_ec',
        time_value: [],
        artist_id: null
      },
      fileTitle: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  created() {
    this.defaultDate()
  },
  mounted() {

  },
  watch: {
    "$route.matched": function ( n ) {
      if (n.length === 2) {
        this.initList()
      }
    }
  },
  methods: {
    rateFormat( value ) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat( value ) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat( value ) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    defaultDate() {
      this.checkedRadio = 1
      // 默认选择
      this.choiceTimeData(this.checkedRadio)
      // this.initList()
    },
    changeMonth() {
      this.rangYm = null
      this.checkedRadio = null

      let ymArr = this.ym.split('-')
      let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
      let daycount = day.getDate();//最后一天

      this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]

      this.initList()
    },
    changeMonths() {
      this.ym = null
      this.checkedRadio = null
      let beginMonth = this.rangYm[0];
      let endMonth = this.rangYm[1];
      let ymArr = endMonth.split('-')
      let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
      let daycount = day.getDate();//最后一天
      this.searchCondition.time_value = [beginMonth + '-01', endMonth + '-' + daycount]

      this.initList()
    },
    changeTime() {
      this.ym = null
      this.rangYm = null
      this.checkedRadio = null
      this.initList()
    },
    chooseArtist( item ) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
      this.initList()

    },
    getLastDays( days ) {
      //几天前的时间
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days);
      return day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    },
    choiceTimeData( days ) {
      //选择时间
      let beginTime = this.getLastDays(days)
      let endTime = this.getLastDays(1)
      this.searchCondition.time_value = [beginTime, endTime];
    },
    radioChange() {
      this.choiceTimeData(this.checkedRadio)
      this.initList()
    },
    incomeOptions( val, defaultIncome, columns ) {
      this.incomes = val
      this.searchCondition.income_code = defaultIncome
      this.columns = columns;
      this.initList()
    },
    choicePlatform( val, columns ) {
      this.searchCondition.income_code = val;
      this.columns = columns;
      this.initList()
    },

    goToPage( routeName ) {
      this.$router.push({path: routeName})
    },
    // 初始化列表
    async initList( type ) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo)
      this.dataList = []
      let {list, pages} = await this.$api.getShopInfoList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      this.fileTitle = '店铺数据'

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.income_code) {
        condition.income_code = this.searchCondition.income_code
        let income = this.incomes.find(( value ) => value.code === condition.income_code)
        if (income)
          this.fileTitle += `[${income.name}]`
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
        this.fileTitle += `[${this.searchCondition.time_value[0]} ~ ${this.searchCondition.time_value[1]}]`
      }

      return condition
    },

    // 响应添加按钮
    async addButton() {
      this.$router.push({
        path: this.$route.path + '/create'
      })
    },
    // 响应编辑按钮
    async editButton( id ) {
      this.$router.push(this.$route.path + '/' + id + '/edit')
    },
    // 相应删除按钮
    async deleteButton( id ) {
      let info = await this.$api.deleteArtist(id)
      if (info == 'ok') {
        this.$nextTick(() => {
          this.initList('del')
        })
      } else {
        this.$message.error(info)
      }
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
    },
    // 处理抽屉确认
    async drawerConfirm() {
      this.searchForm()
    },
    // 搜索
    searchForm() {
      this.offset = 0
      this.initList()
    },
    clearCondition() {
      this.searchCondition = {
        nickname: null,
        group_id: null,
        sign_status: 'Y',
        platforms: []
      }
      this.searchForm()
    },
    //导出表格
    async exportShopData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportShopInfos(searchCondition)
        let name = this.fileTitle

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  },
}
</script>

<style scoped>

.platforms {
  display: flex;
  flex-direction: row;
}

.radio-title {
  font-size: 18px;
  margin-left: 5px;
  line-height: 22px;
}

</style>
