<template>
  <div>
    <el-radio-group :size="size" v-bind="$attrs" v-on="$listeners" v-model="code" @change="choiceIncome"
        class="platforms-logo">
      <el-radio-button :size="size" v-for="(income) in incomes" :label="income.code" :key="income.code">
        <div class="platforms">
          <el-image
              style="width: 22px; height: 22px"
              :src="`${income.logo_url?income.logo_url:'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'}`"
              fit="contain">
          </el-image>
          <span class="radio-title">{{ income.name }}</span>
        </div>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  name: "SelfEShopGroup",
  data() {
    return {
      incomes: [{code: 'shop_tb', name: '淘宝店'}, {code: 'shop_dy', name: '抖音店'}],
      code: null,
      columnsMap: {}
    }
  },
  props: {
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    incomeCode: {
      type: String,
      default() {
        return 'shop_tb'
      }
    },
    showAll: {
      type: Boolean,
      default: false
    },

  },
  watch: {
    // eslint-disable-next-line
    incomeCode: function ( newVal, oldVal ) {
      this.code = newVal
    },
  },
  created() {
    this.code = this.incomeCode
    this.shopInfoSettings()
  },
  mounted() {

  },
  methods: {
    async shopInfoSettings() {
      let {map} = await this.$api.getShopInfoSettings();
      this.columnsMap = map
      let incomes = []
      Object.keys(this.columnsMap).forEach(( key ) => {
        let val = this.columnsMap[key]
        incomes.push({code: val.code, name: val.name})
      })
      this.incomes = incomes
      let defaultIncome = incomes[0] ? incomes[0].code : null
      let columns = this.columnsMap[defaultIncome] ? this.columnsMap[defaultIncome].columns : [];

      this.$emit('incomeOptions', incomes, defaultIncome, columns)
    },
    choiceIncome( val ) {
      // console.log('platform', val)
      let columns = this.columnsMap[val] ? this.columnsMap[val].columns : [];
      this.$emit('choiceIncome', val, columns)
    }
  }
}
</script>
<style scoped>

.platforms {
  display: flex;
  flex-direction: row;
}

.radio-title {
  font-size: 18px;
  margin-left: 5px;
  line-height: 22px;
}

</style>
<style>

</style>
